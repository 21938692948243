import moment from "moment";
export const monthList = [
  "Januar",
  "Februar",
  "März",
  "April",
  "Mai",
  "Juni",
  "Juli",
  "August",
  "September",
  "Oktober",
  "November",
  "Dezember",
];

export const getCETDate = (date = null) => {
  if (!date) date = moment();
  return moment.tz(date, "Europe/Berlin");
};

export const getCurrentCETYear = () => {
  return getCETDate().year();
};

export const getAddedYearInCurrentYear = (number) => {
  return getCETDate().add("years", number).year();
};

export const getCETYearRemainingMonths = () => {
  return monthList.slice(getCETDate().add(2, "month").month());
};

export const getCurrentCETMonth = () => {
  return getCETDate().month() + 1;
};

export const getCETDateTimeStamp = (date = null) => {
  if (!date) date = moment();
  return moment.tz(date, "Europe/Berlin").valueOf();
};

export const getFormattedDate = (date = null, format) => {
  return getCETDate(date).lang("de").format(format);
};

export const customSetIntervalFunction = (
  array,
  apiCallback,
  loaderCallBack,
  callBackFunctionForErrorCheck
) => {
  let itemIndex = 0;
  let loaderTimeout;
  function showMessage() {
    loaderCallBack(true, array[itemIndex].label);
    itemIndex = (itemIndex + 1) % array.length;
  }

  function startLoader() {
    showMessage();
    loaderCallBack(true, array[itemIndex].label);
    if (callBackFunctionForErrorCheck())
      loaderTimeout = setTimeout(startLoader, array[itemIndex].timeOut);
    else {
      clearTimeout(loaderTimeout);
      loaderCallBack();
    }
  }

  function checkApiResponse() {
    const apiResponse = apiCallback();
    if (!apiResponse) {
      setTimeout(checkApiResponse, 1000); // Check every 1 second
    } else {
      clearTimeout(loaderTimeout);
      loaderCallBack(false, "");
    }
  }

  startLoader();
  checkApiResponse();
};

export const increaseValueFromArrayOfObjects = (
  index,
  array,
  apiCallback,
  loaderCallBack,
  callBackFunctionForErrorCheck,
  progressValue
) => {
  const { label, valueToIncrease, timeOut } = array[index];

  const subar = array.slice(0, index + 1);
  const percentageToProgress = subar.reduce(
    (acc, curr) => acc + curr.progressPercentage,
    0
  );
  // Calculate the number of increments required to reach progressPercentage
  const increments = percentageToProgress - progressValue();
  // Perform the increments with a delay
  function incrementWithDelay(incrementCount) {
    if (
      index >= array.length ||
      apiCallback() ||
      !callBackFunctionForErrorCheck() ||
      !valueToIncrease
    ) {
      return;
    }
    if (incrementCount >= increments) {
      increaseValueFromArrayOfObjects(
        index + 1,
        array,
        apiCallback,
        loaderCallBack,
        callBackFunctionForErrorCheck,
        progressValue
      );

      return;
    }
    loaderCallBack(true, label, progressValue() + valueToIncrease);

    setTimeout(() => incrementWithDelay(incrementCount + 1), timeOut);
  }

  incrementWithDelay(0);
};
// If required use this function to calculate yearly data
export const generateYearlyConsumptionData = (fileData) => {
  try {
    const lastYear = fileData.data[fileData.data.length - 1][0];
    const startYearTimestamp = moment
      .tz(lastYear, "Europe/Berlin")
      .startOf("year")
      .valueOf();
    let sum = 0;
    fileData.data.forEach((element) => {
      if (element[0] > startYearTimestamp) {
        sum += element[1];
      }
    });
    return Math.round(sum);
  } catch (error) {
    return fileData.quantity.value;
  }
};
