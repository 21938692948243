<template>
  <div class="number-component">
    <b-form-input
      name="number-input"
      type="text"
      v-show="editableComponent"
      :placeholder="componentPlaceholder"
      class="number-input"
      autocomplete="off"
      :class="styleClass"
      @blur="validateValue()"
      :style="styleDetails"
      :value="getFormattedValue"
      @keyup="onInputChange($event)"
      @keydown="checkValidInput($event)"
      required
    />
    <span class="validation-error" v-show="isShowValidationError">{{
      validationError.error
    }}</span>
    <div class="number-component-view-wrap" v-show="!editableComponent">
      {{ getFormattedValueToDisplay }}
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberComponent",
  data() {
    return {
      validKeyBoardInputs: [110, 190, 46, 37, 39, 188],
      currentNumberInputValue: this.passedNumberValue
        ? this.passedNumberValue
        : "",
      isShowValidationError: false,
    };
  },
  props: {
    passedNumberValue: {
      type: Number,
      require: true,
      default: 0,
    },
    formatNumber: {
      type: Object,
      default: () => ({ thousandseparator: ".", decimalseparator: "," }),
    },
    unit: {
      type: String,
      require: false,
      default: "",
    },
    steps: {
      type: Number,
      default: 1,
    },
    editableComponent: {
      type: Boolean,
      require: false,
      default: false,
    },
    componentPlaceholder: {
      type: String,
      require: false,
    },
    styleDetails: Object,
    styleClass: {
      type: String,
      default: "",
      require: false,
    },
    validationError: {
      type: Object,
      require: false,
      default: () => ({ show: false, error: "", value: null }),
    },
    preventFloatInputValue: {
      type: Boolean,
      default: false,
    },
    minimumDecimalValues: {
      type: Number,
      default: 2,
      require: false,
    },
    maximumDecimalValues: {
      type: Number,
      default: 2,
      require: false,
    },
  },
  methods: {
    onInputChange(event) {
      // current input change value to store in original number abd remove replacer(thousandseparator,decimalseparator)
      const charCode = event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 96 || charCode > 105) &&
        !this.validKeyBoardInputs.includes(charCode)
      ) {
        event.preventDefault();
        return false;
      } else if (
        this.currentNumberInputValue.toString().indexOf(".") == -1 &&
        (charCode == 190 || charCode == 110 || charCode == 188) &&
        !this.preventFloatInputValue
      ) {
        this.currentNumberInputValue =
          String(this.currentNumberInputValue) + ".";

        this.$emit("onNumberInputChange", Number(this.currentNumberInputValue));
      } else {
        const valueAfterDecimal = this.currentNumberInputValue
          .toString()
          .split(".")[1]
          ?.toString();
        if (
          this.currentNumberInputValue.toString().indexOf(".") != -1 &&
          valueAfterDecimal.length > 1
        ) {
          if (
            valueAfterDecimal.indexOf(0) != -1 &&
            (charCode == 8 || charCode == 46)
          ) {
            this.currentNumberInputValue = event.target.value
              .replaceAll(this.formatNumber.thousandseparator, "")
              .replace(this.formatNumber.decimalseparator, ".");
          } else {
            this.currentNumberInputValue =
              Math.round(
                (Number(
                  event.target.value
                    .replaceAll(this.formatNumber.thousandseparator, "")
                    .replace(this.formatNumber.decimalseparator, ".")
                ) +
                  Number.EPSILON) *
                  Math.pow(10, this.maximumDecimalValues)
              ) / Math.pow(10, this.maximumDecimalValues);
          }
        } else {
          this.currentNumberInputValue = event.target.value
            .replaceAll(this.formatNumber.thousandseparator, "")
            .replace(this.formatNumber.decimalseparator, ".");
        }
        event.target.value = this.getFormattedValueFunction(
          this.currentNumberInputValue
        );
        //emit the value to original props value change
        this.$emit("onNumberInputChange", Number(this.currentNumberInputValue));
        return true;
      }
    },
    checkValidInput(event) {
      const charCode = event.keyCode;
      if (
        this.currentNumberInputValue.toString().indexOf(".") > -1 ||
        this.preventFloatInputValue
      ) {
        if (charCode == 190 || charCode == 110 || charCode == 188)
          event.preventDefault();
      }

      if (charCode == 38) {
        this.currentNumberInputValue =
          Number(this.currentNumberInputValue) + Number(this.steps);
      }

      if (charCode == 40) {
        this.currentNumberInputValue =
          Number(this.currentNumberInputValue) - Number(this.steps);
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 96 || charCode > 105) &&
        !this.validKeyBoardInputs.includes(charCode)
      ) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    validateValue() {
      if (
        this.validationError.value &&
        this.validationError.value >= this.currentNumberInputValue
      )
        this.isShowValidationError = true;
      else {
        this.isShowValidationError = false;
        return true;
      }
    },
    getFormattedValueFunction(newInputValue) {
      return newInputValue
        .toString()
        .replace(".", this.formatNumber.decimalseparator)
        .replace(/\B(?=(\d{3})+(?!\d))/g, this.formatNumber.thousandseparator);
    },
  },
  computed: {
    getFormattedValue() {
      return this.getFormattedValueFunction(this.currentNumberInputValue);
    },
    getFormattedValueToDisplay() {
      return this.getFormattedValueFunction(
        (
          Math.round(
            (this.currentNumberInputValue + Number.EPSILON) *
              Math.pow(10, this.maximumDecimalValues)
          ) / Math.pow(10, this.maximumDecimalValues)
        ).toFixed(2)
      );
    },
    getNewFormattedValue() {
      let num = parseFloat(this.currentNumberInputValue);

      if (!num) return "";
      return num
        .toLocaleString("de-DE")
        .replace(".", this.formatNumber.thousandseparator)
        .replace(",", this.formatNumber.decimalseparator);
    },
  },
  created() {
    this.currentNumberInputValue = this.passedNumberValue
      ? this.passedNumberValue
      : "";
  },
  watch: {
    passedNumberValue(newValue) {
      if (!this.editableComponent) this.currentNumberInputValue = newValue;
    },
  },
};
</script>

<style scoped>
.number-input {
  display: block;
  width: 100%;
  height: 40px;
  padding: 4px 15px;
  box-sizing: border-box;
}
.number-unit {
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  font-size: 14px;
}
.number-component-view-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-right: 10px;
}
.validation-error {
  color: red;
}
</style>
