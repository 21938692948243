<template>
  <div class="wrapper w-100 mh-100 d-flex align-items-center">
    <b-row class="price-wrapper w-100 mx-0">
      <b-row class="mt-5 mt-sm-0 w-100 flex-column mx-0">
        <h3 class="mt-3 mb-4">Schritt 3</h3>
        <h1 class="mt-4 mb-0">Preisübersicht</h1>
        <p
          class="mt-3 mb-5"
          v-if="commodity === 'gas' && getCurrentSubDomain == 'selfservice'"
        >
          Wählen Sie die passenden Lieferzeiträume aus.
        </p>
        <p class="mt-3 mb-5" v-else>
          Wählen Sie die passenden Lieferzeiträume und Stromqualität aus.
        </p>
      </b-row>
      <b-row
        v-if="commodity == 'power'"
        class="justify-content-center align-items-center w-100"
        ><div class="tabs_wrap">
          <ul class="p-0">
            <li
              data-tabs="green"
              @click="togglePricingType('green')"
              :class="{ active: selectedPricingType == 'green' }"
            >
              Ökostrom
            </li>
            <li
              data-tabs="standard"
              class="standard-price"
              @click="togglePricingType('standard')"
              :class="{ active: selectedPricingType == 'standard' }"
            >
              Standard
            </li>
          </ul>
        </div></b-row
      >
      <b-row class="justify-content-center w-100">
        <div class="d-flex justify-content-center flex-wrap">
          <b-card
            v-for="(pricingRange, idx) in greenPricePerPeriod"
            bg-variant="light"
            :key="pricingRange.period"
            :class="[
              selectedPrices.includes(pricingRange.period)
                ? 'active'
                : 'selectable',
              { ribbon: selectedPricingType == 'green' && commodity != 'gas' },
            ]"
            class="text-center pricing-card"
            data-ribbon="Ökostrom"
            style="--d: 10px; --c: #62b32a"
          >
            <template #header>
              <span class="big-title">{{ pricingRange.period.slice(-4) }}</span>
              <span>{{ pricingRange.period }}</span>
            </template>
            <span class="price"
              ><NumberComponent
                :passedNumberValue="getPricingAccordingToType(pricingRange)"
            /></span>
            <span style=""> ct/kWh</span>
            <span
              ><b
                >Zuzüglich der gesetzlichen Umlagen und Netzentgelte des
                jeweiligen Netzbetreibers</b
              ></span
            >
            <br />
            <input
              type="checkbox"
              :value="pricingRange.period"
              v-model="selectedPrices"
              class="year-select"
            />
          </b-card>
        </div>
      </b-row>
    </b-row>
  </div>
</template>

<script>
import NumberComponent from "./NumberComponent.vue";

export default {
  name: "DisplayPriceComponent",
  data() {
    return {
      selectedPrices: [],
      selectedPricingType: "standard",
    };
  },
  props: {
    greenPricePerPeriod: {
      type: Array,
      require: true,
    },
    pricePerPeriod: {
      type: Array,
      require: true,
    },
    commodity: {
      type: String,
      require: false,
    },
    makeToast: { type: Function, required: true },
  },
  created() {
    // Set the initial selectedPricingType based on commodity
    if (this.commodity === "power") {
      this.selectedPricingType = "green";
    } else {
      this.selectedPricingType = "standard";
    }
  },
  methods: {
    checkSelectedPricingValidation() {
      if (!this.selectedPrices.length) {
        return this.makeToast(
          "danger",
          "info",
          "Sie müssen mindestens 1 Jahr auswählen"
        );
      } else {
        let selectedData =
          this.selectedPricingType == "green"
            ? this.greenPricePerPeriod
            : this.pricePerPeriod;
        selectedData = selectedData
          .filter((data) => this.selectedPrices.includes(data.period))
          .map((data) => ({
            period: data.period,
            price: data.price,
          }));
        let selectedElectricityType;
        if (this.commodity === "power") {
          selectedElectricityType =
            this.selectedPricingType === "green"
              ? "Ökostrom"
              : "Öko- und Graustrom";
        } else if (this.commodity === "gas") {
          selectedElectricityType = "Öko- und Graugas";
        }
        this.$emit(
          "goToCustomerDataComponent",
          selectedData,
          selectedElectricityType
        );
      }
    },
    togglePricingType(type) {
      if (this.selectedPricingType != type) {
        this.selectedPricingType = type;
        this.selectedPrices = [];
      }
    },
    getPricingAccordingToType(pricingRange) {
      if (this.selectedPricingType == "green") {
        const priceData = this.greenPricePerPeriod.find(
          (data) => data.period === pricingRange.period
        );
        return priceData ? priceData.price : null;
      } else {
        const priceData = this.pricePerPeriod.find(
          (data) => data.period === pricingRange.period
        );
        return priceData ? priceData.price : null;
      }
    },
  },
  computed: {
    getCurrentSubDomain() {
      if (window) {
        const url = window.location.host;
        console.log("url", url);
        const subdomain = url
          .replace(/(https?:\/\/)?(www\.)?/, "")
          .split(".")[1];
        return subdomain;
      }
      return null;
    },
  },
  components: { NumberComponent },
};
</script>

<style scoped>
.wrapper {
  min-height: calc(100vh - 300px);
  margin-left: auto;
  max-width: calc(100% - 300px);
}

.price-wrapper .card {
  margin: 10px;
  min-width: 340px;
  max-width: 380px;
}
.price-wrapper .card .card-header {
  display: flex;
  flex-direction: column;
  background: none;
}
.price-wrapper .card-header .big-title {
  font-size: 35px;
  font-weight: 700;
}
.price-wrapper .card-body {
  display: flex;
  flex-direction: column;
}
.price-wrapper .card-body span {
  margin-bottom: 5px;
}
.price-wrapper .card .price {
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-weight: 500;
}

.price-wrapper .card .year-select {
  height: 40px;
  width: 40px;
  margin: 0 auto;
}
.price-wrapper .card.selectable .year-select {
  animation: pulse 2s infinite;
}

.price-wrapper .card .year-select:checked {
  accent-color: var(--success) !important;
}
.price-wrapper .card .year-select:checked:hover {
  accent-color: var(--success) !important;
}
.no-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
.price-wrapper .card {
  background: #fff !important;
  /* box-shadow: 0 0 5px rgb(0, 0, 0, 0.2); */
  border-radius: 6px;
  transition: all 300ms ease-in-out;
  border-width: 2px;
}

.price-wrapper .card .card-header {
  background: transparent;
}

.price-wrapper .card.active {
  transform: scale(1.03);
  border-color: var(--success) !important;
}
.price-wrapper .card.selectable:hover {
  transform: scale(1.05);
  border-color: var(--success) !important;
}

.price-wrapper .card.active:hover {
  transform: scale(1.05);
  border-color: var(--success) !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--pulse-box-shadow), 0);
  }
  70% {
    -webkit-box-shadow: 0 0 0 6px rgba(var(--pulse-box-shadow), 0.4);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(var(--pulse-box-shadow), 0.6);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--pulse-box-shadow), 0);
    box-shadow: 0 0 0 0 rgba(var(--pulse-box-shadow), 0);
  }
  70% {
    -moz-box-shadow: 0 0 0 3px rgba(var(--pulse-box-shadow), 0.4);
    box-shadow: 0 0 0 3px rgba(var(--pulse-box-shadow), 0.4);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(var(--pulse-box-shadow), 0.6);
    box-shadow: 0 0 0 0 rgba(var(--pulse-box-shadow), 0.6);
  }
}

.price-wrapper .tabs_wrap {
  padding: 5px;
  justify-content: center;
}

.price-wrapper .tabs_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.price-wrapper .tabs_wrap ul li {
  width: 135px;
  text-align: center;
  background: #e9ecf1;
  border-right: 1px solid #c1c4c9;
  padding: 13px 15px;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  list-style-type: none;
  font-size: 18px;
  font-weight: 600;
}

.price-wrapper .tabs_wrap ul li:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.price-wrapper .tabs_wrap ul li:last-child {
  border-right: 0px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.price-wrapper .tabs_wrap ul li:hover,
.price-wrapper .tabs_wrap ul li.active {
  background: var(--success) !important;
  color: #fff;
}

.price-wrapper .tabs_wrap ul li.standard-price:hover,
.price-wrapper .tabs_wrap ul li.standard-price.active {
  background: #0215a573;
}
.price-wrapper .pricing-card.ribbon::before {
  content: attr(data-ribbon);
  position: absolute;
  font-size: var(--f);
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg);
  color: #fff;
  text-align: center;
  border: 1px solid transparent;
  border-bottom: 0;
  transform-origin: bottom left;
  padding: 5px 35px calc(var(--d) + 5px);
  background: linear-gradient(rgba(0, 0, 0, 0.5) 0 0) bottom/100% var(--d)
    no-repeat var(--c);
  background-clip: padding-box;
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    calc(100% - var(--d)) calc(100% - var(--d)),
    var(--d) calc(100% - var(--d)),
    0 100%
  );
  -webkit-mask: linear-gradient(
        135deg,
        transparent calc(50% - var(--d) * 0.707),
        #fff 0
      )
      bottom left,
    linear-gradient(-135deg, transparent calc(50% - var(--d) * 0.707), #fff 0)
      bottom right;
  -webkit-mask-size: 300vmax 300vmax;
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
}
.price-wrapper .pricing-card.left.ribbon::before {
  left: 0;
  right: auto;
  transform: translate(-29.29%, -100%) rotate(-45deg);
  transform-origin: bottom right;
}
@media (max-width: 1499px) {
  .price-wrapper h3 {
    font-size: 22px;
  }
  .price-wrapper h1 {
    font-size: 38px !important;
  }
  .price-wrapper .card {
    min-width: 250px;
    max-width: 300px;
  }
  .price-wrapper .card .year-select {
    height: 30px;
    width: 30px;
  }

  .go-to-steps-button .btn,
  .slp-form .btn {
    width: 220px;
    font-size: 16px;
  }
  .price-wrapper .card-header .big-title {
    font-size: 28px;
  }
  .price-wrapper .card .price {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .price-wrapper h3 {
    font-size: 18px;
    margin-bottom: 30px !important;
  }
  .price-wrapper h1 {
    font-size: 30px !important;
    margin-top: 0 !important;
  }
  .wrapper {
    max-width: 100%;
  }
  .go-to-steps-button .btn,
  .slp-form .btn {
    font-size: 14px;
    height: 50px;
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .price-wrapper h3 {
    font-size: 15px;
    margin-bottom: 10px !important;
  }
  .price-wrapper h1 {
    font-size: 24px !important;
    margin-top: 0 !important;
  }
  .price-wrapper .card {
    min-width: 200px;
    max-width: 270px;
  }
  .price-wrapper .tabs_wrap ul li {
    font-size: 16px;
    padding: 10px 12px;
  }
}
</style>
