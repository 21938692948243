var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper w-100 mh-100 d-flex align-items-center"},[_c('b-row',{staticClass:"period-wrapper"},[_c('b-col',[_c('b-row',{staticClass:"mt-5 mt-sm-0"},[_c('b-col',[_c('h3',{staticClass:"mt-3 mb-5"},[_vm._v("Schritt 1")]),_c('h1',{staticClass:"mt-5 mb-0"},[_vm._v("Belieferungszeitraum auswählen")]),_c('p',{staticClass:"mt-3 mb-5"},[_vm._v(" Wählen Sie den Zeitraum für Ihre Lieferung. Bei Lieferungen, die im aktuellen Jahr starten, muss auch der gewünsche Monat gewählt werden. ")])])],1),_c('b-row',[_c('b-col',{staticClass:"year-selection-wrapper align-content-around d-flex flex-wrap",attrs:{"cols":"12"}},[_c('div',{staticClass:"first-year"},[_c('div',{staticClass:"year-detail justify-content-center",class:{ active: _vm.isActiveYear(_vm.yearSelectionOptions[0]) },on:{"click":function($event){return _vm.toggleYearSelection(_vm.yearSelectionOptions[0], 0)}}},[_c('span',{staticClass:"no-select"},[_vm._v(_vm._s(_vm.yearSelectionOptions[0]))])]),_c('b-row',[_c('b-col',{staticClass:"month-selection-wrapper align-content-around d-flex flex-wrap justify-content-start",class:{ 'show-hover': _vm.showCurrentYearRemainingMonths },attrs:{"cols":"12"}},_vm._l((_vm.currentYearRemainingMonths),function(month,index){return _c('div',{key:month.monthName,staticClass:"month-detail no-select me-2",class:{
                    active:
                      month.monthName == _vm.selectedStartingMonth?.monthName,
                    nextActive:
                      _vm.selectedStartingMonth?.month &&
                      month.month > _vm.selectedStartingMonth?.month,
                  },on:{"click":function($event){return _vm.toggleMonthSelection(month)}}},[_vm._v(" "+_vm._s(month.monthName)+" ")])}),0)],1)],1),_vm._l((_vm.yearSelectionOptions.slice(1)),function(yearDetails,index){return _c('div',{key:yearDetails,staticClass:"year-detail justify-content-center",class:{ active: _vm.isActiveYear(yearDetails) },on:{"click":function($event){return _vm.toggleYearSelection(yearDetails, index)}}},[_c('span',{staticClass:"no-select"},[_vm._v(_vm._s(yearDetails))])])})],2)],1)],1)],1),_c('b-modal',{attrs:{"hide-footer":true,"hide-header":true,"centered":"","no-close-on-esc":"","no-close-on-backdrop":""},model:{value:(_vm.showConfirmChangeModal),callback:function ($$v) {_vm.showConfirmChangeModal=$$v},expression:"showConfirmChangeModal"}},[_c('div',{staticClass:"p-2 warning-modal-container"},[_c('div',{staticClass:"warning-modal-header"},[_vm._v("Achtung")]),_c('div',[_c('p',{staticClass:"my-4"},[_vm._v(" Sind Sie sicher, dass Sie Ihren Preiszeitraum ändern möchten? Ihre aktuellen Daten werden zurückgesetzt ")]),_c('div',{staticClass:"d-flex p-2 justify-content-between"},[_c('b-button',{attrs:{"variant":"btn btn-outline-success w-50"},on:{"click":_vm.onReset}},[_vm._v("Zurücksetzen")]),_c('b-button',{attrs:{"variant":"btn btn-outline-success w-50 ml-4"},on:{"click":_vm.onModalCancel}},[_vm._v("Abbrechen")])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }