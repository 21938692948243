import { axiosGet, axiosPost, axiosPut } from "@/plugins/axios.plugin";

const API_ENDPOINTS = {
  GET_TODOS: "https://jsonplaceholder.typicode.com/todos",
  PARSE_FILE: "https://loadparser.api.codelos.io/v4/parse",
  CALCULATE_METADATA:
    "https://time-series-calculations.api.codelos.io/v4/calculate-metadata",
  SUM_SERIES: "https://time-series-calculations.api.codelos.io/v4/sum",
  SUBTRACT_SERIES:
    "https://time-series-calculations.api.codelos.io/v4/subtract",
  SAVE_SERIES: "https://beta-data.api.codelos.io/files",
  GET_PRICING_API: "https://calculationsv2.api.teamautomations.de/power",
  GET_ACCESS_TOKEN: "https://flow-runner.api.codelos.io/v5/run_flow_by_id",
  CALCULATE_SLP: "https://slp-generator.api.codelos.io/v2/slp-profile",
  CALCULATE_MAPPING: "https://load-mapper-power.api.codelos.io/v3/map",
  CALCULATE_GAS_SLP: "https://slp-generator.api.codelos.io/v3/slp-gas-profile",
};

export const getTodos = (params, options = {}) => {
  return axiosGet(API_ENDPOINTS.GET_TODOS, options);
};

export const parseFileToGetJson = (payload) => {
  return axiosPost(API_ENDPOINTS.PARSE_FILE, payload);
};

export const calculateMetaData = (payload) => {
  return axiosPost(API_ENDPOINTS.CALCULATE_METADATA, payload);
};

export const saveNewSeries = (payload) => {
  return axiosPut(API_ENDPOINTS.SAVE_SERIES, payload);
};

export const sumTwoSeries = (payload, params) => {
  return axiosPost(API_ENDPOINTS.SUM_SERIES, payload, params);
};

export const subtractTwoSeries = (payload, params) => {
  return axiosPost(API_ENDPOINTS.SUBTRACT_SERIES, payload, params);
};

export const getPricing = (payload, params) => {
  return axiosPost(API_ENDPOINTS.GET_PRICING_API, payload, params);
};
export const getAccessToken = (params) => {
  return axiosGet(`${API_ENDPOINTS.GET_ACCESS_TOKEN}`, params);
};

export const getSLPData = (params, options = {}) => {
  return axiosGet(API_ENDPOINTS.CALCULATE_SLP, params);
};

export const calculateMapping = (payload, params) => {
  return axiosPost(API_ENDPOINTS.CALCULATE_MAPPING, payload, params);
};

export const getGasSLPData = (params, options = {}) => {
  return axiosGet(API_ENDPOINTS.CALCULATE_GAS_SLP, params);
};
