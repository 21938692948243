<template>
  <div class="custom-container">
    <b-overlay
      id="overlay-background"
      class="no-select"
      :show="isLoading.show"
      rounded="sm"
    >
      <template #overlay class="">
        <div class="overlay-container">
          {{ isLoading.message }}
          <div
            class="spinner-border text-dark"
            v-if="isLoading.isSpinner"
            role="status"
          >
            <span class="sr-only">Bitte warten...</span>
          </div>
          <b-progress
            :value="isLoading.percentage"
            :striped="true"
            v-else
            class="loader-progress"
          ></b-progress>
        </div>
      </template>
      <!-- Top Bar -->
      <b-col>
        <!--
        <b-row class="px-0 py-5 border-bottom">
          <b-col>
            <h2></h2>
          </b-col>
          <b-col class="text-right">
            <img
              style="width: 250px"
              src="https://res.cloudinary.com/gharnati-com/image/upload/v1686232869/team_Logo_RGB_bdinkm.png"
            />
          </b-col>
        </b-row>
      -->

        <!-- App -->
        <div v-if="activeStep < 4" class="vertical-steps">
          <ul class="step-progress">
            <li
              v-for="(step, idx) in previewStepsOptions"
              class="step-progress-item no-select"
              :class="getStepClasses(idx)"
              :data-string="step.label"
              :key="step.label"
            >
              <span class="">{{ idx + 1 }}</span>
            </li>
          </ul>
        </div>
        <div v-show="activeStep == 0">
          <SelectPeriodComponent
            class="mh-100"
            ref="selectPeriodComponentRef"
            @setSelectedPricingPeriod="setSelectedPricingPeriod"
            @onReset="onReset"
            :showSelectPeriodResetModal="showSelectPeriodResetModal"
          />
          <!-- :removeItemsFromTheLocalStorage="removeItemsFromTheLocalStorage" -->
        </div>
        <div v-show="activeStep == 1" class="profile-selection-removethis">
          <AddLoadDataComponent
            ref="AddLoadDataComponentRef"
            :toggleOverlay="toggleOverlay"
            :makeToast="makeToast"
            :isMappingApiInProgress="isMappingApiInProgress"
            :selectedPricingPeriod="selectedPricingPeriod"
            :setMappingApiInProgress="setMappingApiInProgress"
            :mappedSeriesSummedUpData="mappedSeriesSummedUpData"
            :setMappedSeriesSummedUpData="setMappedSeriesSummedUpData"
            :isMappingSumApiInProgress="isMappingSumApiInProgress"
            :setIsMappingSumApiInProgress="setIsMappingSumApiInProgress"
            :isMappingSubtractApiInProgress="isMappingSubtractApiInProgress"
            :currentStateSelection="currentStateSelection"
            @updateStateChange="handleStateChange"
            :setCurrentStateSelection="setCurrentStateSelection"
            :activeSLPProfile="activeSLPProfile"
            :setActiveSLPProfile="setActiveSLPProfile"
            :setIsMappingSubtractApiInProgress="
              setIsMappingSubtractApiInProgress
            "
            :isSeriesStoreApiInProgress="isSeriesStoreApiInProgress"
            @setSeriesStoreApiInProgress="setSeriesStoreApiInProgress"
            :togglePricingButton="togglePricingButton"
            @setActiveStep="setActiveStep"
            @setActiveProfile="setActiveProfile"
            :activeProfile="activeProfile"
            @setPricingData="setPricingData"
            @toggleReCalculatePricing="toggleReCalculatePricing"
            :commodity="commodity"
            :activeGasType="activeGasType"
            :activeGasProfile="activeGasProfile"
            @setActiveGasType="setActiveGasType"
            @setActiveGasProfile="setActiveGasProfile"
            @setSlpProfileLoader="setSlpProfileLoader"
            :getSlpDataLoader="getSlpDataLoader"
          />
          <!-- :removeItemsFromTheLocalStorage="removeItemsFromTheLocalStorage" -->
        </div>
        <div v-show="activeStep == 2 && pricingOffer.length">
          <DisplayPriceComponent
            class="mh-100"
            :makeToast="makeToast"
            ref="displayPriceComponentRef"
            :commodity="commodity"
            :pricePerPeriod="pricingOffer"
            :greenPricePerPeriod="greenPricingOffer"
            @goToCustomerDataComponent="goToCustomerDataComponent"
          />
        </div>
        <div v-if="activeStep == 3">
          <CustomerDataComponent
            ref="CustomerDataComponentRef"
            :toggleOverlay="toggleOverlay"
            :makeToast="makeToast"
            :pricingAPIresponse="pricingAPIresponse"
            :pricingData="pricingData"
            @goTofeedbackComponent="goTofeedbackComponent"
            @sendFeedbackProps="sendFeedback"
          />
        </div>
        <div v-if="activeStep == 4">
          <feedbackComponent :feedbackProps="feedbackProps" />
        </div>
        <div class="row">
          <div class="my-4 go-to-steps-button col">
            <b-button
              v-if="activeStep !== 0 && activeStep < 4"
              @click="stepBack()"
              variant="btn btn-outline-success"
              v-bind:disabled="isButtonDisable"
              >Zurück</b-button
            >
            <div
              :class="{
                'disabled-pricing-button-wrapper':
                  isPricingButtonEnabled != 'outline-success',
              }"
              v-if="
                isPricingButtonVisible &&
                activeStep == 1 &&
                !activeProfile &&
                activeGasProfile === null
              "
            >
              <b-tooltip
                v-if="
                  reCalculatePricing &&
                  isPricingButtonEnabled != 'outline-success'
                "
                target="disabled-wrapper"
              >
                {{ getCalculatePricingButtonToolTipText }}
              </b-tooltip>
              <span
                v-if="reCalculatePricing"
                id="disabled-wrapper"
                class="d-inline-block"
                tabindex="0"
              >
                <b-button
                  :disabled="
                    isPricingCalculateButtonEnabled != 'outline-success'
                  "
                  :variant="isPricingCalculateButtonEnabled"
                  :class="{
                    'disabled-pricing-button':
                      isPricingCalculateButtonEnabled != 'outline-success',
                  }"
                  @click="getPrice()"
                  >Preis berechnen</b-button
                >
              </span>
              <b-button
                :disabled="isPricingCalculateButtonEnabled != 'outline-success'"
                :variant="isPricingCalculateButtonEnabled"
                :class="{
                  'disabled-pricing-button':
                    isPricingCalculateButtonEnabled != 'outline-success',
                }"
                v-else
                @click="validateAndStepForward()"
                >zur Preisübersicht</b-button
              >
            </div>
            <b-button
              variant="btn btn-outline-success"
              @click="validateAndStepForward()"
              v-if="isForwardButtonActive"
              >Weiter</b-button
            >
            <!-- comment this button -->
            <div
              v-if="activeStep == 3"
              @click="validateAndStepForward()"
              class="custom-design-button"
            >
              Kostenloses Angebot anfordern
            </div>
            <!-- uncomment this button if want normal button  -->
            <!-- <b-button
              v-if="activeStep == 3"
              @click="validateAndStepForward()"
              variant="btn btn-outline-success"
              >Indikatives Angebot anfordern</b-button
            > -->
            <b-button
              v-if="activeStep == 1 && activeProfile == 'slpProfile'"
              variant="btn btn-outline-success"
              @click.prevent="submitSlpProfileData()"
              >SLP-Profil erstellen</b-button
            >

            <b-button
              variant="btn btn-outline-success"
              v-if="activeStep == 1 && activeGasProfile === 'gasProfile1'"
              @click.prevent="validateAndForwardGasProfile()"
              >Weiter</b-button
            >

            <b-button
              variant="btn btn-outline-success"
              v-if="activeStep == 1 && activeGasProfile === 'gasProfile2'"
              v-bind:disabled="isButtonDisable"
              @click.prevent="validateAndForwardGasProfile()"
              ><b-spinner
                variant="#ec6501"
                label="Spinning"
                v-if="getSlpDataLoader"
                small
              ></b-spinner
              ><span v-else>SLP-Profil erstellen</span></b-button
            >
          </div>
        </div>
      </b-col>
    </b-overlay>
  </div>
</template>
<script>
import SelectPeriodComponent from "@/components/SelectPeriodComponent.vue";
import DisplayPriceComponent from "@/components/DisplayPriceComponent.vue";
import CustomerDataComponent from "@/components/CustomerDataComponent.vue";
import feedbackComponent from "@/components/feedbackComponent.vue";
import {
  calculateMetaData,
  saveNewSeries,
  getPricing,
} from "../services/home.services.js";
import {
  increaseValueFromArrayOfObjects,
  getFormattedDate,
} from "../utils/helper.js";
import { refreshAccessToken } from "../plugins/axios.plugin.js";
import NumberComponent from "../components/NumberComponent.vue";
import AddLoadDataComponent from "@/components/ AddLoadDataComponent.vue";
export default {
  name: "Home",
  data() {
    return {
      activeStep: 0,
      selectedPricingPeriod: null,
      mappedSeriesSummedUpData: null,
      isMappingApiInProgress: null,
      isMappingSumApiInProgress: null,
      isMappingSubtractApiInProgress: null,
      currentStateSelection: null,
      activeSLPProfile: null,
      isLoading: { show: false, message: "", percentage: 0, isSpinner: false },
      pricingOffer: [], //[{"period":"01.01.2025 - 31.12.2025","price":14.907106011229331},{"period":"01.01.2026 - 31.12.2026","price":12.565413536050588},{"period":"01.01.2027 - 31.12.2027","price":11.059800309494717}],
      greenPricingOffer: [],
      isPricingButtonVisible: false,
      isSeriesStoreApiInProgress: null,
      activeProfile: null,
      previewStepsOptions: [
        {
          label: "Zeitraum",
        },
        {
          label: "Lieferstellen",
        },
        {
          label: "Preisübersicht",
        },
        {
          label: "Ihre Daten",
        },
      ],
      pricingData: {},
      reCalculatePricing: true,
      //last one should always be null
      showPricingLoaderDetails: [
        {
          label: "Lastgang wird eingelesen...",
          timeOut: 500,
          progressPercentage: 25,
          valueToIncrease: 1.25,
        },
        {
          label: "Lastgang wird analysiert...",
          timeOut: 600,
          progressPercentage: 25,
          valueToIncrease: 1.5,
        },
        {
          label: "Risiko wird berechnet...",
          timeOut: 1000,
          progressPercentage: 35,
          valueToIncrease: 1.75,
        },
        {
          label: "Preis wird berechnet...",
          timeOut: 2000,
          progressPercentage: 15,
          valueToIncrease: null,
        },
      ],
      pricingLoaderInterval: 12,
      activeGasProfile: null,
      activeGasType: null,
      minMaxValueForGas: { min: 300000, max: 5000000 },
      minMaxValueForPower: { min: 100000, max: 1000000 },
      getSlpDataLoader: null,
    };
  },
  props: {
    commodity: { required: true, default: "power", type: String },
  },
  methods: {
    validateAndForwardGasProfile() {
      this.$refs.AddLoadDataComponentRef.validatedGasForm();
    },
    setActiveGasProfile(profile) {
      this.activeGasProfile = profile;
    },
    setActiveGasType(type) {
      if (type) {
        this.activeGasType = type;
      }
    },
    onReset() {
      this.isPricingButtonVisible = false;
      this.isSeriesStoreApiInProgress = null;
      this.pricingData = {};
      this.selectedPricingPeriod = null;
      this.mappedSeriesSummedUpData = null;
      this.pricingOffer = [];
      this.greenPricingOffer = [];
      this.selectedPricingPeriod = null;
      this.isMappingApiInProgress = false;
      this.isMappingSumApiInProgress = false;
      this.isMappingSubtractApiInProgress = false;
      this.$refs.AddLoadDataComponentRef.resetData();
      this.toggleReCalculatePricing();
    },
    setActiveSLPProfile(val) {
      this.activeSLPProfile = val;
    },
    setMappingApiInProgress(val) {
      this.isMappingApiInProgress = val;
    },
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title,
        variant: variant,
        solid: true,
      });
    },
    setSelectedPricingPeriod(value) {
      //set Commoditu selection and year-month selection
      this.selectedPricingPeriod = value;
      this.activeStep = 1;
    },
    setMappedSeriesSummedUpData(data) {
      if (data) {
        this.setPricingData({
          sumLoad: data.id,
          totalQuantity: data.quantity.value,
        });
        // localStorage.setItem(
        //   "sumLoad",
        //   JSON.stringify({ loadId: data.id, quantity: data.sum })
        // );
        // else {
        //   localStorage.removeItem("sumLoad");
        //   localStorage.removeItem("pricingData");
        // }

        if (this.commodity === 'gas') {
          data = {
            ...data,
            id: this.replaceGasName(data.id),
          };
        }
      }
      this.mappedSeriesSummedUpData = data;
    },
    setIsMappingSumApiInProgress(data) {
      this.isMappingSumApiInProgress = data;
    },
    setIsMappingSubtractApiInProgress(data) {
      this.isMappingSubtractApiInProgress = data;
    },
    setCurrentStateSelection(value) {
      this.currentStateSelection = value;
    },
    handleStateChange(val) {
      this.currentStateSelection = val;
    },
    async updateOrSaveSeries(series) {
      try {
        if (series.hasOwnProperty("tags")) delete series.tags;
        const payload = {
          file_content: series,
        };
        const jsonFileResponse = await saveNewSeries(payload);
        if (jsonFileResponse.status === 200) {
          return true;
        }
        return false;
      } catch (error) {
        console.log("error :>> ", error);
        this.makeToast(
          "danger",
          "Erforderlich",
          `Ooops, hier ist etwas schief gelaufen. Bitte probieren Sie es erneut, sollte das Problem weiterhin auftreten, melden Sie sich bei unserem Kundendienst.`
        );
      }
    },
    generatePricePerPeriodData(priceData, periodData) {
      let pricePerPeriodData = [];

      // Iterate over all pricedata and get corresponding prices
      for (let i = 0; i < priceData.length; i++) {
        let priceObj = priceData[i];
        let year = priceObj.year;
        let periodObj = periodData.find((obj) => obj.year === year);

        if (periodObj) {
          let period = periodObj.value;
          let price = priceObj.value;

          let pricePerPeriod = {
            period: period,
            price: price,
          };

          pricePerPeriodData.push(pricePerPeriod);
        }
      }
      return pricePerPeriodData;
    },
    async getPrice() {
      try {
        this.pricingOffer = [];
        this.greenPricingOffer = [];
        if (this.isLoading.percentage == 0) {
          increaseValueFromArrayOfObjects(
            0,
            this.showPricingLoaderDetails,
            () =>
              this.pricingOffer.length ||
              this.greenPricingOffer.length ||
              this.isLoading.percentage >= 100,
            this.toggleOverlay,
            () => this.pricingLoaderInterval,
            () => this.isLoading.percentage
          );
        }
        if (
          this.isMappingApiInProgress ||
          this.isMappingSubtractApiInProgress ||
          this.isMappingSumApiInProgress ||
          !this.mappedSeriesSummedUpData
        )
          setTimeout(() => {
            return this.getPrice();
          }, 2000);
        else {
          if (!this.mappedSeriesSummedUpData.usageHours) {
            const metaResponse = await calculateMetaData({
              loadCurve: this.mappedSeriesSummedUpData,
            });
            await this.updateOrSaveSeries(metaResponse.data);
            this.setMappedSeriesSummedUpData(metaResponse.data);
          }
          await this.calculatePrice();
        }
      } catch (error) {
        console.log("error :>> ", error);
        clearInterval(this.pricingLoaderInterval);
        this.pricingLoaderInterval = null;
        this.toggleOverlay();
        this.makeToast(
          "danger",
          "Erforderlich",
          `Ooops, hier ist etwas schief gelaufen. Bitte probieren Sie es erneut, sollte das Problem weiterhin auftreten, melden Sie sich bei unserem Kundendienst.`
        );
      }
    },

    async calculatePrice() {
      try {
        const pricingStartingDate = getFormattedDate(
          this.selectedPricingPeriod.startingYearTimeStamp,
          "Y-MM-DD"
        );
        const pricingEndingDate = getFormattedDate(
          this.selectedPricingPeriod.endingYearTimeStamp,
          "Y-MM-DD"
        );
        this.setPricingData({
          created: this.mappedSeriesSummedUpData.created,
          pricingFrom: pricingStartingDate,
          pricingTo: pricingEndingDate,
          sumLoad: this.mappedSeriesSummedUpData.id,
          totalQuantity: this.mappedSeriesSummedUpData.quantity.value,
        });
        const formData = new FormData();
        const deliveryPointOverview = this.pricingData.pricingLoadDetails.map(
          (load) => ({
            Zählerart: load.counterType,
            Verbrauch: load.quantity,
            "Service- und Abrechnungspauschale €/p.a.": null,
          })
        );
        //todo
        // this.removeItemsFromTheLocalStorage();
        formData.append("timeseries", this.mappedSeriesSummedUpData.id);
        formData.append("startdatum", pricingStartingDate);
        formData.append("enddatum", pricingEndingDate);
        formData.append("commodity", this.commodity == "gas" ? "Gas" : "Strom");
        formData.append("bundesland", this.currentStateSelection);
        formData.append(
          "webapp_lieferstellenuebersicht",
          JSON.stringify(deliveryPointOverview)
        );
        //! remember to change below data
        formData.append(
          "kundensegment",
          this.commodity == "gas" ? "SLP" : "RLM"
        );
        formData.append("lastgangwirdgemappt", "Nein");
        formData.append("angebotstyp", "Indikativ");
        formData.append("gpgesondert", "Nein");
        formData.append("anrede", "Herr");
        formData.append("internkundenid", "123456");
        formData.append("vorname", "Max");
        formData.append("nachname", "Mustermann");
        formData.append("emailkunde", "selfbepreisung@test.de");
        formData.append("kunde", "energievertrieb");
        formData.append("rechnungsadressestrasse", "Lerchenauerstr");
        formData.append("rechnungsadressehausnummer", "6");
        formData.append("rechnungsadresseplz", "80809");
        formData.append("rechnungsadresseort", "München");
        formData.append("lieferadressestrasse", "Lerchenauerstr");
        formData.append("lieferadressehausnummer", "6");
        formData.append("lieferadresseplz", "80809");
        formData.append("lieferadresseort", "München");
        formData.append("spannungsebenelieferung", "NSP");
        formData.append("spannungsebenemessung", "NSP");
        formData.append("historischerlastgang", "Ja");
        formData.append("ausgerollt", "Ja");
        formData.append("ansprechpartner", "energievertrieb");
        formData.append("pfcvalidation", "Inaktiv");
        formData.append("qpfcbewertung", "Nein");
        formData.append("operatingenvironment", "Test");
        formData.append("is_pricing_webapp", "true");
        formData.append("versandtstromtyp", this.commodity == "power" ? "Öko- und Graustrom" : "");
        formData.append("versandtgastyp", this.commodity == "gas" ? "Öko- und Graugas" : "");
        formData.append(
          "stromserviceundabrechnungspauschalefixjerlmlieferstelleeuroa",
          JSON.stringify([
            {
              year: 0,
              value: 1,
            },
          ])
        );
        formData.append(
          "stromserviceundabrechnungspauschalefixjeslplieferstelleeuroa",
          JSON.stringify([
            {
              year: 0,
              value: 1,
            },
          ])
        );
        formData.append(
          "gasserviceundabrechnungspauschalefixjerlmlieferstelleeuroa",
          JSON.stringify([
            {
              year: 0,
              value: 1,
            },
          ])
        );
        formData.append(
          "gasserviceundabrechnungspauschalefixjeslplieferstelleeuroa",
          JSON.stringify([
            {
              year: 0,
              value: 1,
            },
          ])
        );
        formData.append(
          "vertriebszusatzmargeeuroa",
          JSON.stringify([
            {
              year: 0,
              value: 1,
            },
          ])
        );
        formData.append("angebotspreisvariante", "Fixpreis");

        //pricing api
        const pricingJsonResponse = await getPricing(formData);
        const responseData = pricingJsonResponse.data;
        const greenPriceData = responseData.ökoangebotarbeitspreisctkwh_yearly;
        const greenPeriodData = responseData.period_yearly;
        const priceData = responseData.angebotarbeitspreisctkwh_yearly;
        const periodData = responseData.period_yearly;
        const greenPricePerPeriod = this.generatePricePerPeriodData(
          greenPriceData,
          greenPeriodData
        );
        const pricePerPeriod = this.generatePricePerPeriodData(
          priceData,
          periodData
        );

        // store responseJson
        this.pricingAPIresponse = responseData;
        //store period and interval
        this.pricingOffer = pricePerPeriod;
        this.greenPricingOffer = greenPricePerPeriod;
        if (this.greenPricingOffer.length || this.pricingOffer.length) {
          clearInterval(this.pricingLoaderInterval);
          this.pricingLoaderInterval = null;
          const timeRemaining = 100 - this.isLoading.percentage;
          const timeIntervalToCompleteLoader = setInterval(() => {
            if (this.isLoading.percentage < 100)
              this.toggleOverlay(
                true,
                this.showPricingLoaderDetails[
                  this.showPricingLoaderDetails.length - 1
                ].label,
                this.isLoading.percentage + 1
              );
          }, 2000 / timeRemaining);

          setTimeout(() => {
            clearInterval(timeIntervalToCompleteLoader);
            this.pricingLoaderInterval = 12;
            //set active step pricing
            this.activeStep = 2;
            this.toggleReCalculatePricing(false);
            this.toggleOverlay();
          }, 2000);
        }
      } catch (error) {
        this.isLoading.percentage = 1222;
        this.pricingLoaderInterval = 12;
        this.toggleOverlay();
        clearInterval(this.pricingLoaderInterval);
        this.pricingLoaderInterval = null;
        console.log("error :>> ", error);
        this.makeToast(
          "danger",
          "Erforderlich",
          `
        Ooops, hier ist etwas schief gelaufen. Bitte probieren Sie es erneut, sollte das Problem weiterhin auftreten, melden Sie sich bei unserem Kundendienst.
        `
        );
      }
    },
    stepBack() {
      if (this.activeStep == 1) {
        this.$refs.AddLoadDataComponentRef.checkStepBackValidation();
        return;
      }
      if (this.activeStep == 2) this.activeStep = 1;
      if (this.activeStep == 3) this.activeStep = 2;
      if (this.activeStep == 4) this.activeStep = 3;
    },
    validateAndStepForward() {
      if (this.activeStep == 0) {
        this.$refs.selectPeriodComponentRef.submitYearSelection();
        return;
      }
      if (this.activeStep == 1 && !this.reCalculatePricing) {
        this.activeStep = 2;
        return;
      }
      if (this.activeStep == 1 && this.reCalculatePricing) {
        this.$refs.AddLoadDataComponentRef.checkStepForwardValidation();
        return;
      }
      if (this.activeStep == 2) {
        this.$refs.displayPriceComponentRef.checkSelectedPricingValidation();
        return;
      }
      if (this.activeStep == 3) {
        this.$refs.CustomerDataComponentRef.customFormValidation();
        return;
      }
    },
    getNameWithParsedValue(replaceName, name) {
      const newName = name.split("_");
      newName[0] = replaceName;
      return newName.join("_");
    },
    toggleOverlay(
      show = false,
      message = "",
      percentage = 0,
      isSpinner = false
    ) {
      this.isLoading = { show, message, percentage, isSpinner };
    },
    setActiveStep(value) {
      this.activeStep = value;
    },
    setSeriesStoreApiInProgress(value) {
      this.isSeriesStoreApiInProgress = value;
    },
    togglePricingButton(value) {
      this.isPricingButtonVisible = value;
    },
    // removeItemsFromTheLocalStorage() {
    //   localStorage.removeItem("pricingData");
    //   localStorage.removeItem("sumLoad");
    //   localStorage.removeItem("pricingPeriod");
    // },
    goToCustomerDataComponent(pricing, type) {
      console.log("pricing :>> ", pricing);
      console.log("choosen electrotype", type);
      //data which user selected
      this.customerSelectedPricingData = pricing;
      this.setPricingData({
        priceDetails: pricing,
        powertype: type,
      });
      //Do your code for step 3 here
      this.activeStep = 3;
    },
    sendFeedback(feedbackProps) {
      this.goTofeedbackComponent(feedbackProps);
    },
    goTofeedbackComponent(feedbackProps) {
      this.activeStep = 4;
      this.feedbackProps = feedbackProps;
    },
    setActiveProfile(profile) {
      this.activeProfile = profile;
    },
    setPricingData(data) {
      this.pricingData = { ...this.pricingData, ...data };
    },
    submitSlpProfileData() {
      this.$refs.AddLoadDataComponentRef.triggerSlpProfileSubmit();
    },
    getStepClasses(number) {
      return {
        "is-done": number < this.activeStep,
        current: number === this.activeStep,
      };
    },
    toggleReCalculatePricing(value = true) {
      this.reCalculatePricing = value;
    },
    showSelectPeriodResetModal() {
      if (
        this.selectedPricingPeriod !== null &&
        (this.mappedSeriesSummedUpData !== null ||
          this.isMappingApiInProgress ||
          this.isMappingSumApiInProgress ||
          this.isMappingSubtractApiInProgress ||
          this.isSeriesStoreApiInProgress)
      )
        return true;
      return false;
    },
    replaceGasName(id) {
      if (!id.includes("lastgangdaten-preisrechner-gas"))
        return id.replace(
          "lastgangdaten-preisrechner",
          "lastgangdaten-preisrechner-gas"
        );
      return id;
    },
    summedUpSeriesValueToShow(files) {
      return files.reduce((sum, { quantity }) => sum + quantity, 0);
    },
    setSlpProfileLoader(loader) {
      this.getSlpDataLoader = loader;
    }
  },
  computed: {
    isForwardButtonActive() {
      return (
        [0, 2].includes(this.activeStep) ||
        (this.$refs?.AddLoadDataComponentRef.activeProfile &&
          this.$refs?.AddLoadDataComponentRef.activeProfile == "slp" &&
          this.$refs?.AddLoadDataComponentRef.activeProfile == "rlm")
      );
    },
    isPricingButtonEnabled() {
      if (
        this.isMappingApiInProgress ||
        this.isMappingSubtractApiInProgress ||
        this.isSeriesStoreApiInProgress ||
        (this.isMappingSumApiInProgress && this.activeStep != 2) ||
        this.mappedSeriesSummedUpData == null ||
        (this.commodity === "gas" &&
          (this.mappedSeriesSummedUpData.sum > this.minMaxValueForGas.max ||
            Math.ceil(this.mappedSeriesSummedUpData.sum) <
              this.minMaxValueForGas.min)) ||
        (this.commodity === "power" &&
          (this.mappedSeriesSummedUpData.sum > this.minMaxValueForPower.max ||
            Math.ceil(this.mappedSeriesSummedUpData.sum) <
              this.minMaxValueForPower.min))
      )
        return "outline-secondary";
      return "outline-success";
    },

    isPricingCalculateButtonEnabled() {
      if (
        this.isMappingApiInProgress ||
        this.isMappingSubtractApiInProgress ||
        this.isSeriesStoreApiInProgress ||
        (this.isMappingSumApiInProgress && this.activeStep != 2) ||
        this.mappedSeriesSummedUpData == null ||
        (this.commodity === "gas" &&
          (this.getSummedUpSeries > this.minMaxValueForGas.max ||
            this.getSummedUpSeries < this.minMaxValueForGas.min)) ||
        (this.commodity === "power" &&
          (this.mappedSeriesSummedUpData.sum > this.minMaxValueForPower.max ||
            Math.ceil(this.mappedSeriesSummedUpData.sum) <
              this.minMaxValueForPower.min))
      )
        return "outline-secondary";

      return "outline-success";
    },

    getCalculatePricingButtonToolTipText() {
      if (
        this.mappedSeriesSummedUpData &&
        this.mappedSeriesSummedUpData.sum &&
        ((this.commodity === "gas" &&
          this.mappedSeriesSummedUpData.sum > this.minMaxValueForGas.max) ||
          (this.commodity === "power" &&
            this.mappedSeriesSummedUpData.sum > this.minMaxValueForPower.max))
      )
        return `Preise können nur bis
                ${this.commodity == "power" ? "1.000.000" : "5.000.000"}
                kWh/pro Jahr berechnet werden. Bitte melden sie Sich bei
                energievertrieb@team.de für ein in individuelles Angebot.`;
      else {
        return `Preise können erst ab
                ${this.commodity == "power" ? "100.000" : "300.000"}
                kWh berechnet werden.`;
      }
    },
    getSummedUpSeries() {
      if (this.pricingData?.pricingLoadDetails?.length)
        return this.summedUpSeriesValueToShow(
          this.pricingData.pricingLoadDetails
        );

      return 0;
    },
    isButtonDisable() {
      return this.getSlpDataLoader !== null;
    },
  },
  created() {
    refreshAccessToken();
  },
  components: {
    SelectPeriodComponent,
    NumberComponent,
    DisplayPriceComponent,
    AddLoadDataComponent,
    CustomerDataComponent,
    feedbackComponent,
  },
};
</script>
<style scoped>
.custom-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 90%;
}
.profile-item h4 {
  font-size: 20px;
}

.vertical-steps {
  width: 210px;
  font-family: "Helvetica";
  font-size: 14px;
  height: 636px;
  position: absolute;
  margin-top: 99px;
}

.step-progress {
  position: relative;
  padding-left: 45px;
  list-style: none;
  height: 100%;
}
.step-progress::before {
  display: inline-block;
  /* content: ""; */
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 2px solid #ccc;
}
.step-progress-item {
  position: relative;
  counter-increment: list;
}
.step-progress-item:not(:last-child) {
  padding-bottom: 100px;
}
.step-progress-item:not(:last-child)::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
  border-left: 2px solid #ccc;
}
.step-progress-item > span {
  content: counter(list);
  padding-top: 0;
  width: 50px;
  height: 50px;
  top: 0px;
  margin-left: -54px;
  text-align: center;
  color: white;
  background-color: gray;
  font-size: 21px;
  border-radius: 50%;
  display: flex;
  z-index: 4;
  position: relative;
  justify-content: center;
  align-items: center;
}
.step-progress-item.is-done::before {
  border-left: 2px solid var(--success);
}
.step-progress-item::after {
  content: attr(data-string);
  font-size: 21px;
  display: block;
  z-index: 4;
  position: absolute;
  top: 8px;
  left: unset;
  right: 0;
  text-align: left;
  width: 100%;
  padding-left: 15px;
}
.step-progress-item.current > span {
  border: 2px solid var(--current-active);
  background-color: var(--current-active);
}
.step-progress-item.is-done > span {
  border: 2px solid var(--success);
  background-color: var(--success);
}
.step-progress strong {
  display: block;
}

.go-to-steps-button {
  /* margin-left: 206px; */
  margin-left: auto;
  max-width: calc(100% - 300px);
  justify-content: space-between;
  display: flex;
}
.go-to-steps-button .btn,
.slp-form .btn {
  font-size: 19px;
  font-weight: 700;
  padding: 15px;
  border-radius: 7px;
  border-width: 2px;
  width: 250px;
}
.overlay-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.overlay-container .spinner-border {
  margin-top: 20px;
}
#overlay-background ::v-deep .b-overlay {
  position: fixed !important;
}
.no-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}
.custom-container .disabled-pricing-button {
  pointer-events: none;
}
.custom-container .disabled-pricing-button-wrapper {
  cursor: not-allowed !important;
}
.custom-container .loader-progress {
  width: 500px;
  margin-top: 10px;
}
.custom-design-button {
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: var(--success);
  height: 62.5px;
  width: fit-content;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.28) 0.5px 0.87px 5px 0px;
  font-size: 19px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  transition: transform 0.2s ease-in-out 0s;
  padding: 0 10px;
}
.custom-design-button:hover {
  transform: scale(1.05);
}

@media (max-width: 1499px) {
  .step-progress-item > span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -52px;
    padding-top: 0;
    width: 46px;
    height: 46px;
  }
  .step-progress-item:not(:last-child) {
    padding-bottom: 100px;
  }
  .period-wrapper h3 {
    font-size: 22px;
  }
  .period-wrapper h4 {
    font-size: 38px;
  }
  .go-to-steps-button .btn,
  .slp-form .btn {
    font-size: 16px;
    width: 220px;
  }
  .custom-design-button {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .vertical-steps {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
  .vertical-steps .step-progress li {
    display: inline-block;
    width: calc(100% / 4);
    z-index: 1;
    /* text-align: center; */
    padding-bottom: 30px;
  }

  .vertical-steps .step-progress {
    padding-left: 0;
  }

  .step-progress-item::after {
    top: 50px;
    padding-left: 0;
    /* text-align: center; */
    /* left: -46px; */
  }
  .step-progress-item:not(:last-child)::before {
    display: inline-block;
    left: 0;
    height: 2px;
    width: 100%;
    z-index: 4;
    top: 20px;
    background: #ccc;
  }
  .step-progress-item.is-done::before {
    background: var(--success);
  }
  .step-progress-item > span {
    /* margin: 0 auto; */
    margin-left: 0;
  }
  .go-to-steps-button .btn,
  .slp-form .btn {
    font-size: 14px;
    height: 50px;
    padding: 10px;
    margin: 0 20px;
  }
  .go-to-steps-button {
    justify-content: center;
    max-width: 100%;
  }
  .custom-design-button {
    height: 50px;
  }
}
@media (max-width: 767px) {
  .step-progress-item::after {
    font-size: 14px;
  }
  .period-wrapper h3 {
    font-size: 18px;
  }
  .period-wrapper h4 {
    font-size: 30px;
  }
  .go-to-steps-button {
    max-width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }
  .custom-container {
    max-width: 100%;
    margin: 0;
    padding: 0 10px;
  }
  .step-progress-item > span {
    width: 36px;
    height: 36px;
    font-size: 15px;
  }
  .step-progress-item::after {
    font-size: 11px;
  }
}
@media (max-width: 500px) {
  .custom-container .loader-progress {
    width: 300px;
  }
}
#overlay-background ::v-deep .progress-bar {
  background-color: var(--success) !important;
}
</style>
