<template>
  <div class="wrapper w-100 mh-100 d-flex align-items-center">
    <b-row class="period-wrapper">
      <b-col>
        <b-row class="mt-5 mt-sm-0">
          <b-col>
            <h3 class="mt-3 mb-5">Schritt 1</h3>
            <h1 class="mt-5 mb-0">Belieferungszeitraum auswählen</h1>
            <p class="mt-3 mb-5">
              Wählen Sie den Zeitraum für Ihre Lieferung. Bei Lieferungen, die
              im aktuellen Jahr starten, muss auch der gewünsche Monat gewählt
              werden.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="year-selection-wrapper align-content-around d-flex flex-wrap"
          >
            <!-- First year -->
            <div class="first-year">
              <div
                :class="{ active: isActiveYear(yearSelectionOptions[0]) }"
                class="year-detail justify-content-center"
                @click="toggleYearSelection(yearSelectionOptions[0], 0)"
              >
                <span class="no-select">{{ yearSelectionOptions[0] }}</span>
              </div>
              <b-row>
                <b-col
                  cols="12"
                  class="month-selection-wrapper align-content-around d-flex flex-wrap justify-content-start"
                  :class="{ 'show-hover': showCurrentYearRemainingMonths }"
                >
                  <div
                    v-for="(month, index) in currentYearRemainingMonths"
                    :key="month.monthName"
                    :class="{
                      active:
                        month.monthName == selectedStartingMonth?.monthName,
                      nextActive:
                        selectedStartingMonth?.month &&
                        month.month > selectedStartingMonth?.month,
                    }"
                    class="month-detail no-select me-2"
                    @click="toggleMonthSelection(month)"
                  >
                    {{ month.monthName }}
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- Next years-->
            <div
              v-for="(yearDetails, index) in yearSelectionOptions.slice(1)"
              :key="yearDetails"
              :class="{ active: isActiveYear(yearDetails) }"
              class="year-detail justify-content-center"
              @click="toggleYearSelection(yearDetails, index)"
            >
              <span class="no-select">{{ yearDetails }}</span>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      v-model="showConfirmChangeModal"
      :hide-footer="true"
      :hide-header="true"
      centered
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="p-2 warning-modal-container">
        <div class="warning-modal-header">Achtung</div>
        <div>
          <p class="my-4">
            Sind Sie sicher, dass Sie Ihren Preiszeitraum ändern möchten? Ihre
            aktuellen Daten werden zurückgesetzt
          </p>
          <div class="d-flex p-2 justify-content-between">
            <b-button variant="btn btn-outline-success w-50" @click="onReset"
              >Zurücksetzen</b-button
            >
            <b-button
              variant="btn btn-outline-success w-50 ml-4"
              @click="onModalCancel"
              >Abbrechen</b-button
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  getCurrentCETYear,
  getAddedYearInCurrentYear,
  getCurrentCETMonth,
  getCETYearRemainingMonths,
  monthList,
  getCETDateTimeStamp,
} from "../utils/helper.js";
import moment from "moment";

export default {
  name: "periodComponent",
  data() {
    return {
      yearSelectionOptions: [],
      currentYearRemainingMonths: [],
      firstSelectedYear: null,
      lastSelectedYear: null,
      showCurrentYearRemainingMonths: false,
      selectedStartingMonth: null,
      showConfirmChangeModal: false,
    };
  },
  props: {
    showSelectPeriodResetModal: {
      type: Function,
      default: () => false,
    },
  },
  computed: {},
  methods: {
    onReset() {
      this.$emit("onReset");
      this.firstSelectedYear = null;
      this.selectedStartingMonth = null;
      this.lastSelectedYear = null;
      this.onModalCancel();
    },
    onModalCancel() {
      this.showConfirmChangeModal = false;
    },
    makeToast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title,
        variant: variant,
        solid: true,
      });
    },
    toggleYearSelection(yearDetails, yearIndex) {
      if (this.showSelectPeriodResetModal()) {
        this.showConfirmChangeModal = true;
        return;
      }

      //select first year
      if (!this.firstSelectedYear) {
        this.firstSelectedYear = yearDetails;

        //check if months should be visible or not
        this.checkIsMonthSectionVisible(yearDetails);
      }
      //select last year if it's null
      else if (this.firstSelectedYear) {
        //check if user select less month then last selected year
        if (this.firstSelectedYear > yearDetails) {
          //if year then  switch last year and assign first year new value
          this.lastSelectedYear = this.lastSelectedYear
            ? this.lastSelectedYear
            : this.firstSelectedYear;
          this.firstSelectedYear = yearDetails;

          //check if months should be visible or not
          this.checkIsMonthSectionVisible(yearDetails);
        } else {
          if (
            this.lastSelectedYear == yearDetails &&
            this.lastSelectedYear > this.firstSelectedYear
          ) {
            this.lastSelectedYear =
              this.yearSelectionOptions[
                this.yearSelectionOptions.indexOf(this.lastSelectedYear) - 1
              ];
            return true;
          }

          //if  user again select selected year for toggle it
          if (
            this.firstSelectedYear == yearDetails &&
            this.lastSelectedYear == null
          ) {
            this.selectedStartingMonth = null;
            this.showCurrentYearRemainingMonths = false;
            this.firstSelectedYear = null;
            this.lastSelectedYear = null;
            return true;
          }
          if (
            this.firstSelectedYear == this.lastSelectedYear &&
            this.lastSelectedYear != yearDetails
          ) {
            this.lastSelectedYear = yearDetails;
            return true;
          }
          if (
            this.firstSelectedYear == this.lastSelectedYear &&
            this.lastSelectedYear == yearDetails
          ) {
            this.selectedStartingMonth = null;
            this.showCurrentYearRemainingMonths = false;
            this.firstSelectedYear = null;
            this.lastSelectedYear = null;
            return true;
          }
          if (
            this.firstSelectedYear == yearDetails &&
            this.lastSelectedYear != null
          ) {
            this.firstSelectedYear =
              this.yearSelectionOptions[
                this.yearSelectionOptions.indexOf(this.firstSelectedYear) + 1
              ];
            this.selectedStartingMonth = null;
            this.showCurrentYearRemainingMonths = false;
            return true;
          }
          this.lastSelectedYear = yearDetails;
        }
      }
    },
    toggleMonthSelection(monthDetails) {
      const indexOfSelectedMonth = this.currentYearRemainingMonths.indexOf(
        this.selectedStartingMonth
      );
      if (
        this.selectedStartingMonth.month == monthDetails.month &&
        indexOfSelectedMonth == this.currentYearRemainingMonths.length - 1
      ) {
        return;
      } else if (
        this.selectedStartingMonth.month == monthDetails.month &&
        indexOfSelectedMonth != this.currentYearRemainingMonths.length - 1
      ) {
        this.selectedStartingMonth =
          this.currentYearRemainingMonths[indexOfSelectedMonth + 1];
      } else {
        this.selectedStartingMonth = monthDetails;
      }
    },
    setYearSelectionOptions(skipYear = 0) {
      this.yearSelectionOptions = [...Array(4)].map((number, index) =>
        getAddedYearInCurrentYear(index + skipYear)
      );
    },
    submitYearSelection() {
      if (
        !this.firstSelectedYear ||
        (this.showCurrentYearRemainingMonths &&
          this.currentYearRemainingMonths.length < 6 &&
          !this.lastSelectedYear)
      )
        return this.makeToast(
          "danger",
          "Erforderlich",
          "Der ausgewählte Zeitraum darf nicht kleiner als 6 Monate sein."
        );
      const endingYear = this.lastSelectedYear
        ? this.lastSelectedYear
        : this.firstSelectedYear;
      const startMonth =
        this.showCurrentYearRemainingMonths && this.selectedStartingMonth
          ? this.selectedStartingMonth.month + 1
          : `01`;
      const pricingPeriod = {
        startingYearTimeStamp: getCETDateTimeStamp(
          moment
            .tz(`${startMonth}/01/${this.firstSelectedYear}`, "Europe/Berlin")
            .startOf("month")
        ),
        endingYearTimeStamp: getCETDateTimeStamp(
          moment.tz(`12/31/${endingYear}`, "Europe/Berlin").endOf("month")
        ),
        endingYear: endingYear,
        startingYear: this.firstSelectedYear,
        startingMonth:
          this.showCurrentYearRemainingMonths && this.selectedStartingMonth
            ? this.selectedStartingMonth.month + 1
            : null,
      };
      this.$emit("setSelectedPricingPeriod", pricingPeriod);
      // localStorage.setItem("pricingPeriod", JSON.stringify(pricingPeriod));
    },
    isActiveYear(year) {
      if (this.firstSelectedYear) {
        if (year == this.firstSelectedYear) return true;
        if (
          this.lastSelectedYear &&
          year <= this.lastSelectedYear &&
          year > this.firstSelectedYear
        )
          return true;
      }
      return false;
    },
    checkIsMonthSectionVisible(year) {
      if (
        year == getCurrentCETYear() ||
        (getCurrentCETMonth() == 12 && year - getCurrentCETYear() == 1)
      ) {
        this.firstSelectedYear = year;
        this.selectedStartingMonth = this.currentYearRemainingMonths[0];
        this.showCurrentYearRemainingMonths = true;
      }
    },
  },
  mounted() {
    //check remaining months for year selection range
    if (getCurrentCETMonth() < 11)
      //set current year and upcoming years
      this.setYearSelectionOptions();
    else {
      //else skip the current year
      this.setYearSelectionOptions(1);
    }
  },
  created() {
    //set current year remaining months
    this.currentYearRemainingMonths = getCETYearRemainingMonths().map(
      (month, index) => ({
        monthName: month, // + " " + getCurrentCETYear(),
        month: monthList.indexOf(month),
      })
    );
    // const pricingPeriod = JSON.parse(localStorage.getItem("pricingPeriod"));
    // if (pricingPeriod) {
    //   this.firstSelectedYear = pricingPeriod.startingYear;
    //   this.lastSelectedYear = pricingPeriod.endingYear;
    //   if (pricingPeriod.startingMonth) {
    //     this.selectedStartingMonth = this.currentYearRemainingMonths.find(
    //       (monthDetails) => pricingPeriod.startingMonth == monthDetails.month
    //     );

    //     this.showCurrentYearRemainingMonths = true;
    //   }
    // } else {
    //   this.removeItemsFromTheLocalStorage();
    // }
  },
};
</script>

<style scoped>
.wrapper {
  margin-left: auto;
  max-width: calc(100% - 300px);
}

.warning-modal-container .warning-modal-header {
  font-size: 25px;
  font-weight: bolder;
  text-align: center;
  text-transform: capitalize;
}
.period-wrapper .year-selection-wrapper .year-detail {
  width: 175px;
  background: #fff;
  margin-right: 15px;
  border-radius: 7px;
  color: black;
  line-height: 65px;
  font-size: 19px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 0px;
  margin-bottom: 10px;
  display: inline-block;
  text-align: center;
  height: 220px;
  vertical-align: middle;
  border: 2px solid rgb(165 165 165);
  font-weight: 500;
}

.period-wrapper .year-selection-wrapper .first-year {
  width: 175px;
  margin-right: 15px;
}

.period-wrapper .year-selection-wrapper .first-year .year-detail {
  width: 100%;
  margin-right: 0px;
}

.period-wrapper .year-selection-wrapper .first-year .year-detail.active {
  height: 150px;
}

.period-wrapper .year-selection-wrapper .year-detail span {
  margin-top: 50px;
  display: block;
}

.period-wrapper .year-selection-wrapper .year-detail:hover {
  background-color: rgb(247 247 247 / 36%);
  color: var(--success);
  border: 2px solid;
  transform: scale(1.05);
}

.period-wrapper .year-selection-wrapper .year-detail.active {
  background-color: var(--success);
  border-color: var(--brand-color-dark);
  color: white;
  border: 2px solid;
}

/* .period-wrapper .month-selection-wrapper {
  min-height: 60px;
} */

.period-wrapper .month-selection-wrapper .month-detail {
  opacity: 0;
  cursor: pointer;
  text-align: center;
  outline: none;
  transition-duration: 0.15s;
  border-radius: 7px;
  font-size: 0;
  color: black;
  width: 100%;
  visibility: hidden;
  height: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.period-wrapper .month-selection-wrapper:hover .month-detail:hover {
  background-color: rgb(247 247 247 / 36%);
  color: var(--success);
  border-color: var(--success);
  border: 2px solid rgb(165 165 165);
}

.period-wrapper .month-selection-wrapper .month-detail.active {
  opacity: 1;
  visibility: visible;
  margin-bottom: 15px;
  margin-right: 0;
  height: 60px;
  background-color: var(--success) !important;
  color: white !important;
  border-color: var(--success);
  font-size: 19px;
}

.period-wrapper .month-selection-wrapper.show-hover:hover .month-detail {
  visibility: visible;
  opacity: 1;
  transition-duration: 0.15s;
  height: 60px;
  margin-bottom: 15px;
  font-size: 19px;
  border: 2px solid rgb(165 165 165);
}

.btn {
  font-size: 19px;
  font-weight: 700;
  padding: 15px;
  border-radius: 7px;
  border-width: 2px;
  width: 250px;
}

.no-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.mt-99 {
  margin-top: 99px;
}

.period-wrapper .month-selection-wrapper .month-detail.nextActive {
  background-color: var(--success) !important;
  color: white !important;
  border-color: var(--success);
}
@media (max-width: 1499px) {
  .period-wrapper h3 {
    font-size: 22px;
  }
  .period-wrapper h1 {
    font-size: 38px !important;
  }
  .period-wrapper .year-selection-wrapper .year-detail {
    height: 180px;
  }
  .period-wrapper .year-selection-wrapper .year-detail span {
    margin-top: 20px;
  }
  .period-wrapper .year-selection-wrapper .first-year .year-detail.active {
    height: 120px;
  }
  .period-wrapper .month-selection-wrapper .month-detail.active {
    height: 60px;
  }
  .go-to-steps-button .btn,
  .slp-form .btn {
    width: 190px;
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .wrapper {
    max-width: 100%;
  }
  .period-wrapper .year-selection-wrapper .first-year .year-detail.active {
    height: 80px;
  }
  .period-wrapper .month-selection-wrapper .month-detail.active {
    height: 50px;
  }
  .period-wrapper .year-selection-wrapper .year-detail {
    height: 140px;
  }
  .period-wrapper .year-selection-wrapper .year-detail span {
    margin-top: 0;
  }
  .period-wrapper h3 {
    font-size: 18px;
    margin-bottom: 30px !important;
  }
  .period-wrapper h1 {
    font-size: 30px !important;
    margin-top: 0 !important;
  }
  .go-to-steps-button .btn,
  .slp-form .btn {
    font-size: 14px;
    height: 50px;
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .period-wrapper h3 {
    font-size: 15px;
    margin-bottom: 10px !important;
  }
  .period-wrapper h1 {
    font-size: 24px !important;
    margin-top: 0 !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .period-wrapper .year-selection-wrapper .first-year,
  .period-wrapper .year-selection-wrapper .year-detail {
    width: 100%;
    margin-right: 0;
  }
  .period-wrapper .year-selection-wrapper .year-detail {
    height: 100px;
  }

  .period-wrapper .month-selection-wrapper {
    min-height: unset;
  }
}
</style>
