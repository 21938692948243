import { getAccessToken } from "@/services/home.services";
import axios from "axios";
// const axios = axios.create();
let access_token = `eyJraWQiOiI5M2NHeEY5dHhINTl2SWJpVDR1NDZQQ0VvOFNGZ2xEdno5THg1UVRcL2k3Yz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJmYzU5YmM5YS03MDQzLTRiNGItYjQ4YS0zOWY2ZGUxYmQ2MTkiLCJldmVudF9pZCI6IjkyMzRjZDRjLTU2YTQtNGU4My1hMDBkLTA4NmVmZjQ2ZGNmNyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2ODU0NDQ0ODgsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX25lNjBzOURReSIsImV4cCI6MTY4NzI0MjMwMSwiaWF0IjoxNjg3MjM4NzAxLCJqdGkiOiIyOGE1Y2UwYi1kYjVjLTQ2MzYtYWQzNS03NzZlMWIyZTMxOWUiLCJjbGllbnRfaWQiOiI2MW5wczNzYmtnNXZjcm40dmw1aGR1cWJmNCIsInVzZXJuYW1lIjoiZmM1OWJjOWEtNzA0My00YjRiLWI0OGEtMzlmNmRlMWJkNjE5In0.EbMiThtSB5sCTM9mtW2M4v9doXq2RQYY9uYlHBiQL-Tsk9EQx88Piaia8KefVUjYc-qNMPU5hrxetRZ5ie9sGAaM3V5NbV1Es7xvR6jxx0xVVLstBbmZQ5bOhJfSSZlcm7r0zzJIlaf4e9eqdTFSy5XJk3yuboK_gooHT0BKuluHhtkgeQokmZat2_Hq159VV1XT3h_Obgv-rRE-Z3_74lXQDjxq8SgZ9k7H-N_bEt_EDzDXu2ynK8nDXcwMNOdv096661iZiLB37W4Lp9wJEvhh7p6o0-euLXUKdmzXBekhq5FjHWfKl1mx-zOlvw6jrJ2RE0CKh4NTCUcIy6gsGA`;

axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ` + access_token;

    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export const refreshAccessToken = async () => {
  try {
    const getRefreshTokenResponse = await getAccessToken({
      params: {
        id: 1702876827040,
      },
      headers: {
        Accept: "application/json",
      },
    });
    const access_token = getRefreshTokenResponse.data[0]["value"];
    return access_token;
  } catch (error) {}
};

// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      access_token = await refreshAccessToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);
export const axiosGet = (url, options = {}) => {
  return axios.get(url, options);
};

export const axiosPost = (url, data = {}, options = {}) => {
  return axios.post(url, data, options);
};

export const axiosPut = (url, data = {}, options = {}) => {
  return axios.put(url, data, options);
};
export const axiosDelete = (url, data = {}, options = {}) => {
  return axios.delete(url, { data, ...options });
};

export const axiosPatch = (url, data = {}, options = {}) => {
  return axios.patch(url, data, options);
};

export default axios;
