<template>
  <div class="wrapper w-100 mh-100 d-flex align-items-center">
    <b-row class="offer-sent-wrapper w-100">
      <b-col>
        <b-row class="mt-5 mt-sm-0">
          <b-col>
            <h3 class="mt-3 mb-5"></h3>
            <h1 class="mt-5 mb-0"></h1>
            <p class="mt-3 mb-5"></p>
          </b-col>
        </b-row>
        <b-row class="mx-0">
          <div class="img-wrapper">
            <img
              src="https://res.cloudinary.com/gharnati-com/image/upload/v1662461387/Bildschirmfoto_2022-09-06_um_12.48.13_qj7wjx.png"
              style="width: 100%; margin-bottom: -4px"
            />
            <div
              class="text-element u_bg"
              style="
                --lt-color-gray-100: #f8f9fc;
                --lt-color-white: #fff !important;
                --lt-color-black: #111213 !important;
                --lt-color-transparent: rgba(255, 255, 255, 0) !important;
                color: #3d3d3b;
                box-sizing: border-box;
                -webkit-tap-highlight-color: transparent;
                -webkit-font-smoothing: antialiased;
                margin: 0;
                border: 0;
                font: inherit;
                vertical-align: baseline;
                width: 100%;
                grid-column-end: span 12;
                grid-row-end: span 12;
                padding-top: 580px;
                padding-bottom: 20px;
                background-color: #fff;
              "
            >
              <div
                class="text-element--inner"
                style="padding-top: 20px; padding-bottom: 20px"
              >
                <div class="text-element--content">
                  <header class="text-element--header">
                    <h2
                      class="text-element--headline m-regular--headline"
                      style="
                        --lt-color-gray-100: #f8f9fc;
                        --lt-color-white: #fff !important;
                        --lt-color-black: #111213 !important;
                        --lt-color-transparent: rgba(
                          255,
                          255,
                          255,
                          0
                        ) !important;
                        box-sizing: border-box;
                        -webkit-tap-highlight-color: transparent;
                        -webkit-font-smoothing: antialiased;
                        margin: 0;
                        padding: 0;
                        border: 0;
                        font: inherit;
                        vertical-align: baseline;
                        font-family: Poppins, sans-serif;
                        font-size: 45px;
                        font-weight: 600;
                        line-height: 1.3;
                        margin-bottom: 1rem;
                        color: #ec6608;
                      "
                    >
                      {{ feedbackProps.title }}
                    </h2>
                  </header>
                  <span
                    class="text-element--subline m-regular--subline"
                    style="
                      --lt-color-gray-100: #f8f9fc;
                      --lt-color-white: #fff !important;
                      --lt-color-black: #111213 !important;
                      --lt-color-transparent: rgba(255, 255, 255, 0) !important;
                      --lt-color-overlay-transparent: rgba(
                        0,
                        0,
                        0,
                        0.1
                      ) !important;
                      --lt-shadow-website-overlay: 0 0 7px 0 rgba(0, 0, 0, 0.3) !important;
                      box-sizing: border-box;
                      -webkit-tap-highlight-color: transparent;
                      -webkit-font-smoothing: antialiased;
                      margin: 0;
                      padding: 0;
                      border: 0;
                      font: inherit;
                      vertical-align: baseline;
                      font-family: Poppins, sans-serif;
                      font-size: 18px;
                      font-weight: 600;
                      display: block;
                      margin-bottom: 1rem;
                      letter-spacing: 1px;
                      color: #9ca4a0;
                    "
                    >{{ feedbackProps.message }}</span
                  >
                  <div
                    class="text-element--text-wrap m-regular--col"
                    style="
                      --lt-color-gray-100: #f8f9fc;
                      --lt-color-white: #fff !important;
                      --lt-color-black: #111213 !important;
                      --lt-color-transparent: rgba(255, 255, 255, 0) !important;
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "OfferSuccsessfullySentComponent",
  data() {
    return {
      // Add your component data properties here
    };
  },
  props: {
    feedbackProps: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // Add your component methods here
  },
  components: {
    // Add your component methods here
  },
};
</script>

<style scoped>
.wrapper {
  min-height: calc(100vh - 300px);
  margin-left: auto;
}

.img-wrapper {
  padding: 17px;
}

.img-wrapper img {
  position: fixed;
  top: 0;
  left: 0;
}


@media (max-width: 991px) {
  .wrapper {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
}
</style>
