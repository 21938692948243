<template>
  <div class="wrapper w-100 mh-100 d-flex align-items-center">
    <b-row class="customer-data-wrapper w-100">
      <b-col>
        <b-row class="mt-5 mt-sm-0">
          <b-col>
            <h3 class="mt-3 mb-5">Schritt 4</h3>
            <h1 class="mt-5 mb-0">Indikatives Angebot erhalten</h1>
            <p class="mt-3 mb-5 mb-sm-0">
              Bitte geben Sie Ihre Kontaktdaten an
            </p>
          </b-col>
        </b-row>
        <b-form ref="customerForm" @submit.prevent="customFormValidation">
          <b-row>
            <b-col md="6">
              <h3>Ansprechpartner</h3>
              <div class="form-group">
                <label for="anrede" v-asterisk>Anrede</label>
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="herr"
                      value="Herr"
                      name="anrede"
                      v-model="pricingData.salutation"
                      required
                    />
                    <label class="form-check-label" for="herr">Herr</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="frau"
                      value="Frau"
                      name="anrede"
                      v-model="pricingData.salutation"
                      required
                    />
                    <label class="form-check-label" for="frau">Frau</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      id="divers"
                      value="Divers"
                      name="anrede"
                      v-model="pricingData.salutation"
                      required
                    />
                    <label class="form-check-label" for="divers">Divers</label>
                  </div>
                </div>
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('anrede'),
                  }"
                  >{{ 'Bitte wählen Sie eine dieser Optionen' }}</span
                >
              </div>
              <div class="form-group">
                <label for="vorname" v-asterisk>Vorname</label>
                <input
                  type="text"
                  class="form-control"
                  id="vorname"
                  name="vorname"
                  v-model="pricingData.firstName"
                  required
                />
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('vorname'),
                  }"
                  >{{ 'Bitte füllen Sie dieses Feld aus' }}</span
                >
              </div>
              <div class="form-group">
                <label for="nachname" v-asterisk>Nachname</label>
                <input
                  type="text"
                  class="form-control"
                  id="nachname"
                  name="nachname"
                  v-model="pricingData.lastName"
                  required
                />
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('nachname'),
                  }"
                  >{{ 'Bitte füllen Sie dieses Feld aus' }}</span
                >
              </div>
              <div class="form-group">
                <label for="kundenemail" v-asterisk>E-Mail</label>
                <input
                  type="email"
                  class="form-control"
                  id="kundenemail"
                  name="kundenemail"
                  v-model="pricingData.mail"
                  required
                />
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('kundenemail'),
                  }"
                  >{{ 'Bitte füllen Sie dieses Feld aus' }}</span
                >
              </div>
            </b-col>
            <b-col md="6">
              <h3>Rechnungsadresse</h3>
              <div class="form-group">
                <label for="kunde" v-asterisk>Firma</label>
                <input
                  type="text"
                  class="form-control"
                  id="kunde"
                  name="kunde"
                  v-model="pricingData.company"
                  required
                />
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('kunde'),
                  }"
                  >{{ 'Bitte füllen Sie dieses Feld aus' }}</span
                >
              </div>
              <div class="form-group">
                <label for="rechnungsadressestrasse" v-asterisk>Straße & Hausnummer</label>
                <input
                  type="text"
                  class="form-control"
                  id="rechnungsadressestrasse"
                  name="rechnungsadressestrasse"
                  v-model="pricingData.street"
                  required
                />
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('rechnungsadressestrasse'),
                  }"
                  >{{ 'Bitte füllen Sie dieses Feld aus' }}</span
                >
              </div>
              <div class="form-group">
                <label for="rechnungsadresseplz" v-asterisk>PLZ</label>
                <input
                  type="text"
                  class="form-control"
                  id="rechnungsadresseplz"
                  name="rechnungsadresseplz"
                  v-model="pricingData.zipCode"
                  required
                />
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('rechnungsadresseplz'),
                  }"
                  >{{ 'Bitte füllen Sie dieses Feld aus' }}</span
                >
              </div>
              <div class="form-group">
                <label for="rechnungsadresseort" v-asterisk>Ort</label>
                <input
                  type="text"
                  class="form-control"
                  id="rechnungsadresseort"
                  name="rechnungsadresseort"
                  v-model="pricingData.place"
                  required
                />
                <span
                  class="form-validation-error"
                  :class="{
                    show: !checkFieldError('rechnungsadresseort'),
                  }"
                  >{{ 'Bitte füllen Sie dieses Feld aus' }}</span
                >
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAddressCard,
  faFileInvoiceDollar,
} from "@fortawesome/free-solid-svg-icons";
import { refreshAccessToken } from "../plugins/axios.plugin.js";

// Add the imported icons to the library
library.add(faAddressCard, faFileInvoiceDollar);

export default {
  name: "CustomerDataComponent",
  data() {
    return {
      isDirty: false,
    };
  },
  props: {
    toggleOverlay: {
      type: Function,
      required: true,
    },
    pricingAPIresponse: {
      type: Object,
      required: true,
    },
    pricingData: {
      type: Object,
      required: true,
    },
    makeToast: {
      type: Function,
      required: true,
    },
  },
  methods: {
    customFormValidation() {
      this.isDirty = true;
      if (this.$refs.customerForm.checkValidity()) {
        this.prepareData();
      } else {
        return;
      }
    },

    prepareData() {
      const data = this.pricingAPIresponse;
      this.pricingData.commodity = data.commodity;
      this.pricingData.slpservicekey = data.slp_service_key;
      this.pricingData.rlmssericekey = data.rlm_service_key;
      this.pricingData.offeryearlyconsumption = data.angebotjahresverbrauchkwh;
      const pricingData = this.pricingData;
      for (let i = 0; i < pricingData.pricingLoadDetails.length; i++) {
        delete pricingData.pricingLoadDetails[i].tsDetails;
      }
      console.log(pricingData);
      this.sendOffer(pricingData);
    },

    async sendOffer(pricingData) {
      this.toggleOverlay(true, "Angebot wird versendet...", 0, true);

      try {
        const access_token = await refreshAccessToken();
        var url =
          "https://flow-runner.api.codelos.io/v6/run_flow_by_id?id=1724476483692";
        var formData = new FormData();
        formData.append("data", JSON.stringify(pricingData));

        var requestOptions = {
          method: "POST",
          headers: {
            Authorization: access_token,
          },
          body: formData,
        };

        fetch(url, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            if (result.includes("E-Mail erfolgreich versendet!")) {
              this.$emit("sendFeedbackProps", {
                feedbackType: "success",
                title: "Vielen Dank",
                message:
                  "Das Angebot wurde erfolgreich an die von Ihnen hinterlegte E-Mailadresse gesendet.",
              });
              this.toggleOverlay();
            } else {
              this.$emit("sendFeedbackProps", {
                feedbackType: "error",
                title: "Versenden Fehlgeschlagen",
                message:
                  "Ein unbekannter Fehler ist aufgetreten, das Angebot konnte leider nicht versendet werden.",
              });
              this.toggleOverlay();
            }
          })
          .catch((error) => console.log("error", error));
      } catch (error) {
        console.log("Error refreshing access token:", error);
      }
    },
    checkFieldError(name) {
      if (!this.isDirty) {
        return false;
      }
      return !document.getElementsByName(name)[0].checkValidity();
    },
  },
  directives: {
    asterisk: {
      bind: function (el) {
        el.innerHTML += ' <span style="color: red;">*</span>';
      },
    },
  },
};
</script>

<style scoped>
.wrapper {
  min-height: calc(100vh - 300px);
  margin-left: auto;
  max-width: calc(100% - 300px);
}

.customer-data-wrapper .form-group {
  margin-bottom: 20px;
}

.customer-data-wrapper label {
  font-weight: bold;
}

.customer-data-wrapper h3 {
  margin-top: 30px;
}

.customer-data-wrapper i {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 10px;
}

.form-group {
  min-height: 70px;
}

.form-check.form-check-inline {
  margin-top: 9px;
}

.form-check.form-check-inline input {
  height: 20px;
  width: 20px;
}
.form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #333 !important;
}

.form-check-inline {
  margin-right: 1.75rem;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1.5px solid #8f8c8c;
  transition: border-color 0.3s, background-color 0.3s;
  padding: 0;
}

input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: none;
  background-color: var(--success);
  border: 1.5px solid var(--success);
}

input[type="radio"]:checked {
  outline-color: var(--success);
}

input[type="radio"]:focus {
  outline: none;
}
.customer-data-wrapper .form-validation-error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.customer-data-wrapper .show {
  visibility: hidden;
}
@media (max-width: 1499px) {
  .customer-data-wrapper .col h3 {
    font-size: 22px;
  }
  .customer-data-wrapper .col h1 {
    font-size: 38px !important;
  }
  .custom-design-button {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .form-check-inline {
    margin-right: 15px;
  }
}
@media (max-width: 991px) {
  .wrapper {
    max-width: 100%;
  }
  .customer-data-wrapper .col h3 {
    font-size: 18px;
    margin-bottom: 30px !important;
  }
  .customer-data-wrapper .col h1 {
    font-size: 30px !important;
    margin-top: 0 !important;
  }
}
@media (max-width: 767px) {
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .customer-data-wrapper .col h3 {
    font-size: 15px;
    margin-bottom: 10px !important;
  }
  .customer-data-wrapper .col h1 {
    font-size: 24px !important;
    margin-top: 0 !important;
  }
  .custom-design-button {
    height: 50px;
  }
}
</style>
