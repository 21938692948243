<template>
  <div id="app">
    <Home :commodity="commodity"></Home>
  </div>
</template>

<script>
import Home from "./pages/Home.vue";
import "moment-timezone";

export default {
  name: "App",
  data() {
    return {
      commodity: "",
    };
  },
  methods: {
    setCommoditySubDomain() {
      if (window) {
        try {
          const url = window.location.host;
          const subdomain = url
            .replace(/(https?:\/\/)?(www\.)?/, "")
            .split(".")[0];
          if (subdomain) {
            if (
              subdomain.startsWith("localhost") ||
              subdomain.startsWith("127")
            )
              this.commodity = "power";
            else this.commodity = subdomain;
          } else {
            this.commodity = "gas";
          }
        } catch (error) {
          this.commodity = "gas";
        }
      }
    },
    setAppStyle() {
      let brandColor = "000";
      let lightBrandColor = "000";
      let boxShadow = "";
      if (this.commodity === "power") {
        brandColor = "#62b32a";
        lightBrandColor = "#a9d58c";
        boxShadow = "98, 179, 42";
      }
      if (this.commodity === "gas") {
        brandColor = "#ec6501";
        lightBrandColor = "#ffaa6b";
        boxShadow = "236, 101, 1";
      }

      document.documentElement.style.setProperty("--success", brandColor);
      document.documentElement.style.setProperty(
        "--current-active",
        lightBrandColor
      );
      document.documentElement.style.setProperty(
        "--pulse-box-shadow",
        boxShadow
      );
    },
  },
  created() {
    this.setCommoditySubDomain();
    this.setAppStyle();
  },
  components: {
    Home,
  },
};
</script>

<style>
body {
  min-height: 100vh;
}
#app {
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.btn-outline-success {
  color: var(--success) !important;
  border-color: var(--success) !important;
}
.btn-outline-success:hover {
  color: #fff !important;
  background-color: var(--success) !important;
  border-color: var(--success) !important;
}
h1 {
  font-size: 3rem !important;
  font-weight: 700 !important;
}

:focus-visible {
  outline: none;
}

:focus:not(.year-select) {
  box-shadow: none !important;
}
</style>
